import React, { useState, createContext, useEffect, useContext } from 'react';
import { User, SignupCredentials, SignupInviteCredentials, SigninCredentials, Tokens, Feature } from '../types';
import { AuthService } from '../services/auth.service';
import { tokensStorage, userStorage, allowedFeaturesStorage } from '../helper/storage'
import { getRefreshTokenHttpInterceptor } from '../interceptors';
import { mainNotificationContext } from 'app/modules/shared/context/main-notification-context';
import { HttpService, applicationConfiguration } from 'app/modules/shared';
import { ApplicationConfiguration } from 'app/modules/shared/types';
import { ExternalAuthType } from 'app/modules/admin/features/settings/auth-settings/types';

export interface AuthContextData {
  user?: User;
  getUser: () => Promise<User>,
  signup: (credentials: SignupCredentials) => Promise<void>;
  signupInvite: (credentials: SignupInviteCredentials) => Promise<void>;
  signupFirstUser: (credentials: SignupCredentials) => Promise<void>;
  confirmSignup: (token: string) => Promise<User>;
  resendConfirmLink: (email: string) => Promise<void>;
  signin: (credentials: SigninCredentials) => Promise<User>;
  signout: () => Promise<void>;
  requestPasswordReset: (email: string) => Promise<void>
  validatePasswordResetToken: (token: string) => Promise<void>
  resetPassword: (token: string, password: string, password_confirm: string) => Promise<void>;
  refreshTokens: () => Promise<void>,
  finishExternalSignin: (authType: ExternalAuthType, service: string, code: string, state: string | undefined) => Promise<User>
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

/**
 * Context provider for authentification hook
 */
export function WithAuthContext({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element | null {
  const mainNotification = useContext(mainNotificationContext)
  const config = useContext(applicationConfiguration)
  const [authChecked, setAuthChecked] = useState(false)
  const [user, setUser] = useState<User | undefined>(userStorage.getUser());
  const auth = getAuthMethods(user, setUser, config);

  useEffect(() => {
    const [interceptor, onError] = getRefreshTokenHttpInterceptor(auth, mainNotification, config)
    HttpService.addResponseInterceptor(interceptor, onError)
  }, [])

  useEffect(() => {
    const accessToken = tokensStorage.getAccessToken()
    const user = userStorage.getUser()

    if (!accessToken) {
      // Situation when there's no token but user exists can't be in stable system.
      // Might be only in dev environment. So perform signout just in case.
      if (user) {
        auth.signout()
      }

      setAuthChecked(true)
      return
    }

    async function updateUser() {
      try {
        await auth.getUser()
      } catch (error) {
        auth.signout()
      }

      setAuthChecked(true)
    }

    // Check auth state on initial page load
    updateUser();
  // eslint-disable-next-line
  }, []);

  return authChecked ?
    <AuthContext.Provider value={auth}>{children}</AuthContext.Provider> :
    null
}

/**
 * Methods and data dealing with authentication.
 */
function getAuthMethods(user: User | undefined, setUser: CallableFunction, config: ApplicationConfiguration): AuthContextData {
  /**
   * Perform new user signup
   */
  async function signup(credentials: SignupCredentials) {
    await AuthService.signup(credentials)
  }

  /**
   * Perform first app user signup
   */
  async function signupFirstUser(credentials: SignupCredentials) {
    const tokens = await AuthService.signupGetTokens(credentials)
    saveTokens(tokens)
  }

  /**
   * Perform signup by invitation
   */
  async function signupInvite(credentials: SignupInviteCredentials) {
    const tokens = await AuthService.signupInviteGetTokens(credentials)
    saveTokens(tokens)
  }

  /**
   * Confirm new user signup
   */
  async function confirmSignup(token: string) {
    const tokens = await AuthService.confirmSignup(token)
    !!tokens && saveTokens(tokens)

    return await getUser()
  }

  /**
   * Request the repeating of sending of signup confirmation link
   */
  async function resendConfirmLink(token: string) {
    return await AuthService.resendConfirmLink(token)
  }

  /**
   * Authenticate registered user
   */
  async function signin(credentials: SigninCredentials) {
    const tokens = await AuthService.signin(credentials);
    saveTokens(tokens)

    return await getUser()
  }

  /**
   * Complete OAuth authentication
   */
  async function finishExternalSignin(authType: ExternalAuthType, service: string, code: string, state: string | undefined) {
    const tokens = await AuthService.finishExternalSignin(authType, service, code, state)
    saveTokens(tokens)

    return await getUser()
  }

  /**
   * Refresh auth tokens
   */
   async function refreshTokens() {
    const refreshToken = tokensStorage.getRefreshToken()
    if (refreshToken) {
      const tokens = await AuthService.refreshTokens(refreshToken)
      tokens && saveTokens(tokens)
    }
  }

  /**
   * Get current authenticated user details
   */
  async function getUser() {
    const user = await AuthService.getUser();
    const allowedFeatures = (user?.allowedFeatures ?? [])as Feature[]

    // We need to set features here, not waiting till they are loaded with config,
    // because waiting will cause race conditions for pages we're on, and we can encounter
    // forbidden error.
    allowedFeaturesStorage.setFeatures(allowedFeatures)
    delete user.allowedFeatures
    userStorage.saveUser(user)
    setUser(user)

    await config.loadConfiguration()

    // Rewrite features, obtained with config, by obtained with user, to avoid slitest chance
    // of race conditions or missmatch
    allowedFeaturesStorage.setFeatures(allowedFeatures)

    return user
  }

  /**
   * Signout
   */
  async function signout() {
    await AuthService.signout()

    userStorage.clearUser()
    tokensStorage.clearAccessToken()
    tokensStorage.clearRefreshToken()
    allowedFeaturesStorage.clearFeatures()

    setUser(undefined);
  }

  /**
   * Request password reset
   */
  async function requestPasswordReset(email: string) {
    await AuthService.requestPasswordReset(email);
  }

  /**
   * Check if password reset token is valid
   */
  async function validatePasswordResetToken(token: string) {
    await AuthService.validatePasswordResetToken(token);
  }

  /**
   * Set new password
   */
  async function resetPassword(token: string, password: string, password_confirm: string) {
    await AuthService.resetPassword(token, password, password_confirm);
  }

  /**
   * Save tokens to some storage to use them later
   */
  function saveTokens(tokens: Tokens) {
    tokensStorage.saveAccessToken(tokens.access_token)
    tokensStorage.saveRefreshToken(tokens.refresh_token)
  }

  return {
    user,
    getUser,
    signin,
    signout,
    signup,
    signupInvite,
    signupFirstUser,
    confirmSignup,
    resendConfirmLink,
    requestPasswordReset,
    validatePasswordResetToken,
    resetPassword,
    refreshTokens,
    finishExternalSignin
  };
}
