import React from 'react'
import { setPagesData } from './pages'
import { PageForMatching, PageData } from './types'
import { Feature, UserRole } from '../../auth/types'
import { Layout } from '../../../components/layouts'
import { ThreatsOverviewOnForbidden } from '../../user/features/threats-overview/on-forbidden';
import { UploadsListOnForbidden } from 'app/modules/reports/features/uploads/on-forbidden'
import { TrendsOnForbidden } from 'app/modules/user/features/trends/on-forbidden'
import { LeadersOnForbidden } from 'app/modules/user/features/leaders/on-forbidden'

//Home
const HomePage = React.lazy(() => import('../../../components/home-page/home-page'))

//Admin
const AdminFeatures = React.lazy(() => import('../../admin/admin'))
const AdminSettings = React.lazy(() => import('../../admin/features/settings/settings'))
const Stat = React.lazy(() => import('../../admin/features/stat/stat'))
const Jobs = React.lazy(() => import('../../admin/features/jobs/jobs'))
const AdminUserManagement = React.lazy(() => import('../../admin/features/user-management/user-management'))
const AdminScanSources = React.lazy(() => import('../../admin/features/scan-sources/scan-sources'))
const AuditLogger = React.lazy(() => import('../../admin/features/audit-logger/audit-logger'))

//Report pages
const SearchResult = React.lazy(() => import('../../reports/features/search/search-result'))
const AdvancedSearch = React.lazy(() => import('../../reports/features/search/advanced-search'))
const UploadsList = React.lazy(() => import('../../reports/features/uploads/uploads'))
const ReportInitialPage = React.lazy(() => import('../../reports/features/report-initial-page/report-initial-page'))
const ReportPage = React.lazy(() => import('../../reports/features/base-layout/page'))
const ReportOverviewRedirect = React.lazy(() => import('../../reports/features/base-layout/overview-redirect'))
const ReportsFlowFeatures = React.lazy(() => import('../../reports/reports-flow-features'))

//User pages
const Profile = React.lazy(() => import('../../user/features/profile/profile'))
const MyUploadsPage = React.lazy(() => import('../../user/features/uploads/my-uploads-page'))
const MyDownloads = React.lazy(() => import('../../user/features/downloads/my-downloads'))
const ThreatsOverview = React.lazy(() => import('../../user/features/threats-overview/threats-overview'))
const Trends = React.lazy(() => import('../../user/features/trends/trends'))
const Leaders = React.lazy(() => import('../../user/features/leaders/leaders'))

//Auth pages
const Signin = React.lazy(() => import('../../auth/features/signin/signin'))
const ExternalSigninRedirect = React.lazy(() => import('../../auth/features/signin/external-redirect'))
const Signup = React.lazy(() => import('../../auth/features/signup/signup'))
const FirstSignupRedirect = React.lazy(() => import('../../auth/features/signup/first-signup-redirect'))
const InviteRedirect = React.lazy(() => import('../../auth/features/signup/invite-redirect'))
const RequestPasswordReset = React.lazy(() => import('../../auth/features/request-password-reset/request-password-reset'))
const ResetPassword = React.lazy(() => import('../../auth/features/reset-password/reset-password'))
const ConfirmSignup = React.lazy(() => import('../../auth/features/confirm-signup/confirm-signup'))

//Help pages
const FaqPage = React.lazy(() => import('../../help/faq'))
const StatusPage = React.lazy(() => import('../../help/status/status'))
const AtomFeed = React.lazy(() => import('../../feed/atom'))
const ArchiveFeed = React.lazy(() => import('../../archives'))
const TermsConditions = React.lazy(() => import('../../terms/terms'))
const PrivacyPolicy = React.lazy(() => import('../../terms/privacy'))
const CookiePolicy = React.lazy(() => import('../../terms/cookie'))

//Custom error
const QuotaExceeded = React.lazy(() => import('../../../components/errors/quota-exceeded'))
const NotFound = React.lazy(() => import('../../../components/errors/not-found'))

const scanUrl = '/scan'
const reportGroupUrl = '/uploads/:flowId'

/**
 * Data for all local site pages
 */
const pagesData: { [key: string]: PageData } = {
  // Admin
  'admin-home': {
    'url': '/admin',
    'auth': UserRole.Auditor,
    'redirect': 'admin-stat-home'
  },
  'admin-user-management-home': {
    'url': '/admin/user-management',
    'feature': Feature.User_management,
    'redirect': {page: 'admin-user-management', urlParams: {'subpage': 'users'}}
  },
  'admin-user-management': {
    'title': 'Admin User Management',
    'url': '/admin/user-management/:subpage',
    'feature': Feature.User_management,
    'component': AdminUserManagement,
    'wrapper': AdminFeatures,
    'layout': Layout.Admins
  },
  'admin-user-management-item': {
    'title': 'Admin User Management',
    'url': '/admin/user-management/:subpage/:itemId',
    'feature': Feature.User_management,
    'component': AdminUserManagement,
    'wrapper': AdminFeatures,
    'layout': Layout.Admins
  },
  'admin-scan-sources-home': {
    'url': '/admin/scan-sources',
    'feature': Feature.Site_management,
    'redirect': {page: 'admin-scan-sources', urlParams: {'subpage': 'files'}}
  },
  'admin-scan-sources': {
    'title': 'Admin Scan Sources',
    'url': '/admin/scan-sources/:subpage',
    'feature': Feature.Site_management,
    'component': AdminScanSources,
    'wrapper': AdminFeatures,
    'layout': Layout.Admins
  },
  'admin-settings-home': {
    'url': '/admin/settings',
    'feature': Feature.Site_management,
    'redirect': {page: 'admin-settings', urlParams: {subpage: 'config'}}
  },
  'admin-settings': {
    'title': 'Admin Settings',
    'url': '/admin/settings/:subpage',
    'feature': Feature.Site_management,
    'component': AdminSettings,
    'wrapper': AdminFeatures,
    'layout': Layout.Admins
  },
  'admin-stat-home': {
    'url': '/admin/stat',
    'feature': Feature.Restricted_statistics,
    'redirect': {page: 'admin-stat', urlParams: {subpage: 'reports'}}
  },
  'admin-stat': {
    'title': 'Site Statistics',
    'url': '/admin/stat/:subpage',
    'feature': Feature.Restricted_statistics,
    'component': Stat,
    'wrapper': AdminFeatures,
    'layout': Layout.Admins
  },
  'admin-jobs-home': {
    'url': '/admin/jobs',
    'feature': Feature.Jobs_info,
    'redirect': {page: 'admin-jobs', urlParams: {subpage: 'statistics'}}
  },
  'admin-jobs': {
    'title': 'Scheduled Jobs',
    'url': '/admin/jobs/:subpage',
    'feature': Feature.Jobs_info,
    'component': Jobs,
    'wrapper': AdminFeatures,
    'layout': Layout.Admins
  },
  'admin-audit-logger-home': {
    'url': '/admin/audit-logger',
    'feature': Feature.Restricted_statistics,
    'redirect': {page: 'admin-audit-logger', urlParams: {subpage: 'admin'}}
  },
  'admin-audit-logger': {
    'title': 'Audit Logger',
    'url': '/admin/audit-logger/:subpage',
    'feature': Feature.Restricted_statistics,
    'component': AuditLogger,
    'wrapper': AdminFeatures,
    'layout': Layout.Admins
  },

  // User
  'user-profile': {
    'title': 'My profile',
    'url': '/users/profile',
    'auth': UserRole.User,
    'component': Profile
  },
  'sign-in': {
    'title': 'SignIn',
    'url': '/auth/signin',
    'auth': UserRole.Guest,
    'component': Signin
  },
  'external-auth-redirect': {
    'title': 'External Auth SignIn Redirect',
    'url': '/auth/signin/:service/callback',
    'auth': UserRole.Guest,
    'component': ExternalSigninRedirect
  },
  'sign-up': {
    'title': 'SignUp',
    'url': '/auth/signup',
    'component': Signup,
    'feature': Feature.Signup
  },
  'sign-up-confirm': {
    'title': 'Confirm SignUp',
    'url': '/auth/confirm',
    'component': ConfirmSignup,
    'feature': Feature.Signup
  },
  'first-signup-redirect': {
    'title': 'Initial SignUp Redirect',
    'url': '/auth/signup/redirect',
    'component': FirstSignupRedirect,
    'customAuthValidation': true
  },
  'sign-up-invite': {
    'title': 'SignUp by Invitation',
    'url': '/auth/invite',
    'component': Signup,
    'feature': Feature.Signup_invite
  },
  'invite-signup-redirect': {
    'title': 'Invite SignUp Redirect',
    'url': '/auth/invite/redirect',
    'component': InviteRedirect,
    'customAuthValidation': true
  },
  'request-password-reset': {
    'title': 'Request Password Reset',
    'url': '/auth/request-password-reset',
    'auth': UserRole.Guest,
    'component': RequestPasswordReset
  },
  'reset-password': {
    'title': 'Reset Password',
    'url': '/auth/reset-password',
    'auth': UserRole.Guest,
    'component': ResetPassword
  },

  // Report
  'home': {
    'url': '/',
    'auth': UserRole.Any,
    'redirect': 'scan'
  },
  'scan': {
    'url': scanUrl,
    'component': HomePage,
    'feature': Feature.Scan
  },
  'search-result': {
    'title': 'search-result',
    'url': '/search-result',
    'customAuthValidation': true,
    'component': SearchResult
  },
  'advanced-search': {
    'title': 'advanced-search',
    'url': '/advanced-search',
    'feature': Feature.Advanced_search,
    'component': AdvancedSearch
  },
  'upload-initial-page': {
    'title': 'Upload reports',
    'url': reportGroupUrl,
    'component': ReportInitialPage,
    'wrapper': ReportsFlowFeatures,
    'feature': Feature.Scan
  },
  'report-overview-redirect': {
    'url': reportGroupUrl + '/reports/:reportId',
    'auth': UserRole.Any,
    'component': ReportOverviewRedirect
  },
  'report-new-overview-redirect': {
    'url': '/reports/:hash/:reportId',
    'auth': UserRole.Any,
    'component': ReportOverviewRedirect
  },
  'report': {
    'title': 'analysis-report',
    'url': reportGroupUrl + '/reports/:reportId/:subpage',
    'component': ReportPage,
    'wrapper': ReportsFlowFeatures,
    'feature': Feature.Scan
  },
  'report-new': {
    'title': 'analysis-report',
    'url': '/reports/:hash/:reportId/:subpage',
    'component': ReportPage,
    'wrapper': ReportsFlowFeatures,
    'feature': Feature.Scan
  },
  'my-reports': {
    'title': 'My reports',
    'url': '/me/reports',
    'auth': UserRole.User,
    'component': MyUploadsPage
  },
  'my-downloads': {
    'title': 'My downloads',
    'url': '/me/downloads',
    'auth': UserRole.User,
    'component': MyDownloads
  },
  'threats-overview': {
    'title': 'Threats overview',
    'url': '/threats-overview',
    'auth': UserRole.User,
    'component': ThreatsOverview,
    'onForbidden': ThreatsOverviewOnForbidden
  },
  'trends': {
    'title': 'Global Trends',
    'url': '/trends',
    'auth': UserRole.User,
    'component': Trends,
    'onForbidden': TrendsOnForbidden
  },
  'leaders': {
    'title': 'Leaders Board',
    'url': '/leaders',
    'auth': UserRole.User,
    'component': Leaders,
    'onForbidden': LeadersOnForbidden
  },
  'all-reports': {
    'title': 'reports',
    'url': '/reports',
    'component': UploadsList,
    'feature': Feature.All_reports,
    'onForbidden': UploadsListOnForbidden
  },
  'help': {
    'url': '/help/faq',
    'title': 'faq-title',
    'exact': false,
    'auth': UserRole.Any,
    'component': FaqPage
  },
  'status': {
    'title': 'status',
    'url': '/help/status',
    'auth': UserRole.Any,
    'exact': false,
    'component': StatusPage
  },
  'terms': {
    'url': '/terms',
    'title': 'terms-conditions',
    'auth': UserRole.Any,
    'component': TermsConditions
  },
  'privacy': {
    'url': '/privacy',
    'title': 'privacy-policy',
    'auth': UserRole.Any,
    'component': PrivacyPolicy
  },
  'cookie-policy': {
    'url': '/cookie-policy',
    'title': 'cookie-policy',
    'auth': UserRole.Any,
    'component': CookiePolicy
  },
  'feed': {
    'url': '/feed',
    'title': 'feed',
    'exact': true,
    'feature': Feature.Feed_access,
    'component': AtomFeed
  },
  'archives': {
    'url': '/archives',
    'title': 'archives',
    'auth': UserRole.Any,
    'feature': Feature.Daily_feed,
    'component': ArchiveFeed
  },
  'quota-exceeded': {
    'url': '/error/quota-exceeded',
    'title': 'quota-exceeded',
    'auth': UserRole.Any,
    'component': QuotaExceeded
  },
  'not-found': {
    'url': '/error/not-found',
    'title': 'not-found',
    'auth': UserRole.Any,
    'component': NotFound
  }
}

// List of pages paths, that we use to detect current route's path template
export const pagesForMatching: PageForMatching[] = Object.keys(pagesData).map(pageKey => {
  const pageData = pagesData[pageKey]
  return {'path': pageData.url}
})

/**
 * Define pages data here, to be able to import
 *  pages.tsx to various components and avoid circular references,
 *  in case when that component is used in pages data.
 */
export function initPages(): void {
  setPagesData(pagesData)
}
