import BaseFormatter from './base'
import { TreeNode, NodeType } from '../../types/tree'
import { ReportFilterSection, ReportFilterSectionType, ReportFilterSettings, ReportFilterStat, ReportPageFilterSettings, ReportPageMultiFileFilterSettings } from '../../components/filter/types'
import { EmulationCategory, EmulationFilterStat, EmulationItem } from '../../features/emulation-data/types'
import { mainFileKey } from '../../types'
import { getActionCategory } from '../../features/emulation-data/helpers'
import { camelCaseToWords, inc } from 'app/modules/shared/helpers'
/**
 * Format report response data for emulation data page
 */
export default class EmulationDataFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    let resource: any = this.getResource(data, 'file')
    if (!resource) return

    const filter: ReportPageMultiFileFilterSettings = {files: {}}

    // input file
    const [emulationData, fileFilter] = this.formatResource(resource)
    const result: any = {}

    if (emulationData) {
      emulationData.title = resource.submitName
      result.input = emulationData
      filter.files[mainFileKey] = fileFilter
    }

    // extracted files
    const extractedFiles = resource.extractedFiles
    if (extractedFiles && extractedFiles.length) {
      const extracted: {[fileKey: string]: any} = {}

      for (const file of extractedFiles) {
        const fileKey = file.digests['SHA-256']
        const [emulationData, fileFilter] = this.formatResource(file)

        if (emulationData) {
          filter.files[fileKey] = fileFilter
          emulationData.title = fileKey
          extracted[fileKey] = emulationData
        }
      }

      if (Object.keys(extracted).length) {
        result.extracted = extracted
      }
    }

    // downloaded files
    resource = this.getResource(data, 'file-download')
    const downloadedFiles = resource?.fileDownloadResults
    if (downloadedFiles) {
      const downloaded: {[fileKey: string]: any} = {}

      for (const file of downloadedFiles) {
        const fileKey = file.digests['SHA-256']
        const [emulationData, fileFilter] = this.formatResource(file)

        if (emulationData) {
          filter.files[fileKey] = fileFilter
          emulationData.title = fileKey
          downloaded[fileKey] = emulationData
        }
      }

      if (Object.keys(downloaded).length) {
        result.downloaded = downloaded
      }
    }

    // result
    if (Object.keys(result).length) {
      report.emulation_data = {
        data: result,
        filter: filter
      }
    }
  }

  formatResource(resource: { [key: string]: any }): any {
    const hasEmulationMetaData = resource?.emulationMetaData
    if (!resource?.emulationData || !resource?.emulationData.length) {
      return []
    }

    let meta = {}
    if (hasEmulationMetaData) {
      let metaData = resource?.emulationMetaData;
      const functionCount = this.formatFunctionCount(metaData)

      if (functionCount) {
        metaData = {
          ...metaData,
          Overview: {
            ...metaData.Overview,
            FunctionCount: functionCount
          }
        };

        meta = metaData;
      }
    }

    const [emulationData, filter] = this.formatItems(resource.emulationData)
    const result: any = {
      data: emulationData,
      tree: this.makeTree(emulationData as any),
      meta: meta
    }

    if (resource?.emulationExitCode !== undefined) {
      result.exitCode = resource?.emulationExitCode
    }

    return [result, filter]
  }

  formatFunctionCount(metaData: { [key: string]: any }): null | { [key: string]: any } {
    const array = [];
    for (const key in metaData.Overview?.FunctionCount) {
      array.push({ index: key, value: metaData.Overview.FunctionCount[key] as number });
    }

    if (!array.length) {
      return null
    }

    array.sort(function (a, b) {
      return a.value - b.value;
    });

    const sortedFunctionCount: { [key: string]: any } = {};

    for (const item of array) {
      sortedFunctionCount[item.index] = item.value
    }

    return sortedFunctionCount
  }

  formatItems(data: { [key: string]: any }[]): [{[key: string]: any}, ReportPageFilterSettings] {
    const sectionsCache: {[key: string]: boolean} = {}
    const sections = this.getFilterSectionsTmpl()
    const stat = this.getFilterStatTmpl()

    const formatted = data.map(emulationItem => {
      const item: EmulationItem = {...emulationItem} as EmulationItem
      item.category = getActionCategory(item.action)

      if (item.additionalInformation?.Command) {
        item.commandLowerForFilter = item.additionalInformation?.Command.toLocaleLowerCase()
      }

      this.gatherFiterSections(item, sections, sectionsCache)
      this.gatherFiterStat(item, stat)
      this.splitApiCallArguments(item)

      return item
    })

    return [formatted, {sections: Object.values(sections), stat}]
  }

  gatherFiterSections(
    item: EmulationItem,
    sections: {[sectionName: string]: ReportFilterSection},
    sectionsCache: {[key: string]: boolean}
  ) {
    if (typeof sectionsCache[item.category] === 'undefined') {
      sections.category.items.push({value: item.category, name: camelCaseToWords(item.category)})
      sectionsCache[item.category] = true
    }
    if (typeof sectionsCache[item.action + '-action'] === 'undefined') {
      sections.type.items.push({value: item.action, name: camelCaseToWords(item.action)})
      sectionsCache[item.action + '-action'] = true
    }

    if (item.source && typeof sectionsCache[item.source] === 'undefined') {
      sections.source.items.push({value: item.source})
      sectionsCache[item.source] = true
    }
  }

  gatherFiterStat(item: EmulationItem, stat: ReportFilterStat) {
    stat.types.category[item.category] = inc(stat.types.category[item.category])
    stat.types.type[item.action] = inc(stat.types.type[item.action])
    item.source && (stat.types.source[item.source] = inc(stat.types.source[item.source]))
    stat.total++
  }

  getFilterSectionsTmpl() {
    const sections: {[sectionName: string]: ReportFilterSection} = {
      search: {type: ReportFilterSectionType.Search, name: 'search', items: [], placeholder: 'search'},
      category: {type: ReportFilterSectionType.Flags, name: 'category', items: []},
      type: {type: ReportFilterSectionType.Flags, name: 'type', items: []},
      source: {type: ReportFilterSectionType.Flags, name: 'source', items: []},
    }

    return sections
  }

  getFilterStatTmpl() {
    const stat: EmulationFilterStat = {
      types: {category: {}, type: {}, source: {}}, total: 0
    }

    return stat
  }

  splitApiCallArguments(item: { [key: string]: any }): void {
    if (item.additionalInformation?.PCode) {
      delete item.additionalInformation.PCode.error;
      delete item.additionalInformation.PCode.Error;
    }
    if ('action' in item && item['action'] === "CallAPI") {
      if (item.additionalInformation?.Arguments) {
        const args = item.additionalInformation.Arguments
        if (Array.isArray(args)) {
          item.additionalInformation.Arguments = args.map((arg: string) => this.parseArgs(`${arg}`))
        } else {
          item.additionalInformation.Arguments = this.parseArgs(`${args}`);
        }
      }
    }
  }

  parseArgs(args: string): { [key: string]: any }[] {
    const parts = args.split(',').map(item => item.trim());
    const result: { [key: string]: any }[] = [];

    parts.forEach((part, idx) => {
      part = part.replace(/^ByVal\s+/, '');

      if (part.startsWith('(') && part.endsWith(')')) {
        // for key-value pairs, get value for its key
        const [key, value] = this.getKeyValue(part);
        result.push({ name: `Param ${idx++}`, ...this.getValue(key, value) });
      } else if (/^[-+]?\d+\.\d+$/.test(part)) { 
        // for value only args, try to determine its type from the value
        result.push({ name: 'Float', value: part });
      } else if (/^[-+]?\d+$/.test(part)) {
        result.push({ name: 'Long', value: parseInt(part) });
      } else {
        result.push({ name: 'String', value: part });
      }
    })

    return result;
  }

  /**
   * @name getKeyValue
   * @param input string in format "(key value)" or "key value"
   * @returns [key value]
   */
  getKeyValue(input: string): string[] {
    const content = (input.startsWith('(') && input.endsWith(')')) ?
      input.substring(1, input.length - 1) : input;
    const spacePos = content.indexOf(' ');
    const key = content.substring(0, spacePos);
    const value = content.substring(spacePos + 1, content.length);
    return [key, value];
  }

  /**
   * @name getValue
   * @param key value type
   * @param value value have different format for its type
   * @returns
   */
  getValue(key: string, value: string): { [key: string]: any } {
    switch (key) {
      case 'StringValue':
        return { name: 'String', value };
      case 'ConcatedStringValue':
        // for ConcatedStringValue, concatenate all values connected using + sign
        {
          const values = value.split(' + ').map(item => item.trim());
          let result = ''
          // concatenate values only
          for (const val of values) {
            const [k, v] = this.getKeyValue(val);
            const { value } = this.getValue(k, v);
            result += value;
          }

          return { name: 'String', value: result };
        }
      case 'VbValue':
        {
          const [subKey, subValue] = this.getKeyValue(value);
          if (subKey === 'Long' || subKey === 'Integer') {
            return { name: subKey, value: parseInt(subValue) };
          } else if (subKey === 'UDT') {
            /*
              format: (UDT Book1.INFO) UDT(INFO\n
                Key1 = Value1\n
                ...
                Keyn = Valuen
              )
            */
            if (!subValue.startsWith('(')) {
              if (subValue.startsWith('UDT(')) {
                return this.parseUDT(subValue);
              } else {
                return { name: subKey, value: subValue };
              }
            }

            const spacePos = subValue.indexOf(')')
            const udtValue = subValue.substring(spacePos + 1).trim();
            return this.parseUDT(udtValue);
          } else {
            return { name: subKey, value: subValue };
          }
        }
      default:
        return { name: key, value };
    }
  }

  /**
   * @name parseUDT
   * @param value UDT(INFO\nKey1 = Value1\n...\nKeyn = Valuen)
   * @returns { key1: value1, ..., keyn: valuen }
   */
  parseUDT(value: string): { [key: string]: any } {
    if (!value.startsWith('UDT(') || !value.endsWith(')')) {
      return {}
    }

    value = value.substring(4, value.length - 1);
    const parts = value.split('\n');
    if (parts.length < 1) return {};

    const name = parts[0];
    const args = parts.slice(1);
    const result = [];
    for (const arg of args) {
      const eqPos = arg.indexOf('=')
      if (eqPos < 0) continue;
      const key = arg.substring(0, eqPos).trim();
      const value = arg.substring(eqPos + 1).trim();

      if (value.startsWith('(') && value.endsWith(')')) {
        const [k, v] = this.getKeyValue(value);
        const { name: type, value: val } = this.getValue(k, v);
        result.push({ name: key, type, value: val });
      } else {
        result.push({ name: key, type: 'String', value: arg });
      }
    }

    return { name, value: result }
  }

  makeTree(data: any[]): TreeNode | null {

    const actions: { [key: string]: string[] } = {
      [NodeType.process]: ['StartProcess'],
      [NodeType.file]: ['CreateFile', 'ShellCreatedFile', 'WriteTextFile', 'WriteBinaryFile', 'CopyFile', 'MoveFile', 'CopyProgram', 'MoveProgram'],
      [NodeType.network]: ['HttpRequest'],
      [NodeType.script]: ['ExecuteScript'],
      [NodeType.execute]: ['ExecuteSchedulerJob', 'ExecQuery'],
    }
    let root: TreeNode | null = null

    const urls: string[] = []
    for (const item of data) {
      if (item.action === 'HttpRequest') {
        const url = item.additionalInformation?.URI
        if (!urls.includes(url)) {
          urls.push(url)
        }
      }
    }

    let parent: TreeNode | null = null
    let idx = 1
    for (const item of data) {
      const node: TreeNode = {
        id: `${idx++}`,
        parent: '',
        type: NodeType.other,
        action: item.action,
        interesting: item.interesting ?? false,
        source: item.source,
        extra: {
          pageLink: `#${item.dataUUID.replace(/-/g, '')}`
        },
        original: item,
        childs: []
      }

      item.description && (node.extra.description = item.description)
      item.additionalInformation && (node.extra = { ...item.additionalInformation, ...node.extra })

      for (const key in actions) {
        if (actions[key].includes(item.action)) {
          node.type = key as NodeType
        }
      }

      if (item.action === 'ShellCreatedFile' &&
        item.additionalInformation?.Command &&
        typeof item.additionalInformation?.Command === 'string' &&
        !node.extra?.File
      ) {
        const args = item.additionalInformation.Command.split(' ');
        if (args.length > 1) {
          node.extra.File = args[1];
        }
      }

      if (node.type === NodeType.other) {
        if (!!item.File || !!item.additionalInformation?.File) {
          node.type = NodeType.file
          node.extra.File = item.File ?? item.additionalInformation?.File
        } else if (node.extra.URI) {
          node.type = NodeType.network
        } else if (node.action === 'CallAPI' && (
          node.extra.Alias?.startsWith('URLDownloadToFile') ||
          node.extra.Entrance?.startsWith('URLDownloadToFile')
        )) {
          node.type = NodeType.network
          if (Array.isArray(item.additionalInformation?.Arguments)) {
            const args = item.additionalInformation.Arguments
            if (args.length > 1) {
              node.extra.URI = typeof args[1] === 'string' ? args[1] : args[1].value
            }
          } else {
            const args = item.additionalInformation?.Arguments?.split(',')
            if (args && args.length > 1) {
              node.extra.URI = args[1]
            }
          }

          if (urls.includes(node.extra.URI)) continue;
        } else if (node.action === 'CallAPI' && (
          node.extra.Alias === 'gethostbyname' || node.extra.Entrance === 'gethostbyname')
        ) {
          node.type = NodeType.network
          const args = item.additionalInformation?.Arguments
          if (Array.isArray(args)) {
            if (typeof args[0] === 'string') {
              node.extra.URI = args[0]
            } else {
              node.extra.URI = args[0].value
            }
          }

          if (node.extra.URI) {
            const slashPos = node.extra.URI.indexOf('/')
            if (slashPos > 0) {
              node.extra.URI = node.extra.URI.substring(0, slashPos)
            }
          }
        } else {
          continue
        }
      }

      if (node.type === NodeType.process) {
        if (item.additionalInformation) {
          node.id = item.additionalInformation.Pid
          const parentPid = item.additionalInformation.ParentPid
          if (parentPid === '00000000') {
            if (root) {
              parent = root
              node.parent = parent.id
              parent.childs.push(node)
              parent = node
            } else {
              parent = root = node
            }
          } else {
            parent = this.searchNode(root, parentPid)
            if (!parent) break

            node.parent = parent.id
            parent.childs.push(node)
            parent = node
          }
        }
      } else {
        if (!parent) break
        node.parent = parent.id
        parent.childs.push(node)
      }
    }

    return root
  }

  searchNode(root: TreeNode | null, pid: string): TreeNode | null {
    if (!root || root.type !== NodeType.process) return null

    if (root.extra.Pid === pid) return root

    for (const child of root.childs) {
      const node = this.searchNode(child, pid)
      if (node) return node
    }

    return null
  }
}
