import { User, SignupCredentials, SigninCredentials, SignupInviteCredentials, Tokens } from '../types';
import { apiRoutes } from '../../shared/routing/api-routes'
import { HttpService } from '../../shared/services/http.service';
import { ExternalAuthType } from 'app/modules/admin/features/settings/auth-settings/types';

export const AuthService = {
  async signup(credentials: SignupCredentials): Promise<void> {
    await HttpService.post(apiRoutes.signup, credentials)
  },

  async signupGetTokens(credentials: SignupCredentials | SignupInviteCredentials): Promise<Tokens> {
    return await HttpService.post(apiRoutes.signup, credentials)
  },

  async signupInviteGetTokens(credentials: SignupCredentials | SignupInviteCredentials): Promise<Tokens> {
    return await HttpService.post(apiRoutes.signup_invite, credentials)
  },

  async signin(credentials: SigninCredentials): Promise<Tokens> {
    return await HttpService.post(apiRoutes.signin, credentials)
  },

  async signout(): Promise<void> {
    return await HttpService.get(apiRoutes.signout)
  },

  async confirmSignup(token: string): Promise<Tokens> {
    return await HttpService.get(apiRoutes.confirmSignup + '?token=' + token)
  },

  async resendConfirmLink(email: string): Promise<void> {
    await HttpService.get(apiRoutes.resendConfirmLink + '?email=' + email)
  },

  async finishExternalSignin(
    authType: ExternalAuthType,
    service: string,
    payload: string,
    state: string | undefined
  ): Promise<Tokens> {
    const url = (
      apiRoutes.signinExternal
        .replace('{service}', service)
        .replace('{authType}', authType)
    )
    const data: {[key: string]: string} = {payload}
    if (state) {
      data.state = state
    }

    return await HttpService.post(url, data)
  },

  async getUser(): Promise<User> {
    return await HttpService.get(apiRoutes.user)
  },

  async requestPasswordReset(email: string): Promise<void> {
    await HttpService.post(apiRoutes.requestPasswordReset, {email})
  },

  async validatePasswordResetToken(token: string): Promise<void> {
    return await HttpService.get(apiRoutes.validatePasswordResetToken + '?token=' + token)
  },

  async resetPassword(token: string, password: string, password_confirm: string): Promise<void> {
    await HttpService.post(apiRoutes.resetPassword, {token, password, password_confirm})
  },

  async refreshTokens(token: string): Promise<Tokens> {
    return await HttpService.post(apiRoutes.refresh, {token})
  }
};
