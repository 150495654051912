import React from 'react'
import ReactGA4 from 'react-ga4'
import ReactGA from 'react-ga'
import { ApplicationConfiguration } from "../types"
import { useEffect, useState } from 'react'
import { NavigateFunction } from 'react-router'
import { logger } from 'app/components/logger'
import { useReport } from 'app/modules/reports/hooks'

const urlValidator = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export function isValidUrl(url: string): boolean {
  return urlValidator.test(url)
}

export function emphasizeUrls(text: string, urlText: string | null = null): string {
  const match = text.match(urlValidator)
  if (match) {
    const url = match[0]
    const element = `<a href="${url}" target="_blank">${urlText ?? url}</a>`
    return text.replace(url, element)
  }

  return text
}

export function decimalToHexString(numb: number) {
  if (numb < 0) {
    numb = 0xFFFFFFFF + numb + 1;
  }

  return '0x' + numb.toString(16).toUpperCase();
}

//Sets or clears given http query param and reloads page
export function updateSearchQuery(
  url: string,
  query: URLSearchParams,
  navigate: NavigateFunction,
  name: string | {[key: string]: string | undefined},
  value?: string | undefined
) {
  function setQueryParam(name: string, value: string | undefined) {
    value === undefined || value === '' ? query.delete(name) : query.set(name, value as string)
  }

  if (typeof name !== 'string') {
    Object.keys(name).forEach(key => {
      setQueryParam(key, name[key])
    })
  } else {
    setQueryParam(name, value)
  }

  const queryStr = query.toString()

  navigate(`${url}${queryStr ? `?${queryStr}` : ''}`);
}

// Check if string is sha256
export function isSha256(str: string, strict = false) {
  return (
    typeof str === 'string' && (
      strict ?
      !!str.match(/^[a-z0-9]{64}$/ig) :
      str.length === 64 && str.indexOf(' ') === -1
    )
  )
}

export function countItems(data: any[] | {[key: string]: any}, limit?: number) {
  let count = 0

  if (Array.isArray(data)) {
    count += data.length
    for (let i = 0; i < data.length; i++) {
      count += countItems(data[i])

      if (limit && count >= limit) {
        return count
      }
    }
  } else if (typeof data === 'object') {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key];
        count += countItems(element) + 1 // + 1 - for current element that we check

        if (limit && count >= limit) {
          return count
        }
      }
    }
  }

  return count
}

// Check if object or array has too much elements to be used in FE json viewer.
// Too much here just means that it would hit performance.
export function isJsonViewTooMuchNested(data: any, limit?: number) {
  const count = countItems(data, limit)
  return count > 1000
}

// Check if we have text-like content
export function isAsciiData(data: string | undefined) {
  return !/[^\u0000-\u007f]+/.test(data || '')
}

// Escape string to use it in RegExp constructor
export function escapeRegex(str: string) {
  return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

// Cut string to needed max length with `...`
export function ellipsis(str: string | undefined, limit: number) {
  return (
    str && str.length >= limit ?
    str.substring(0, limit) + '...' :
    str
  )
}

// Format difference between two timestamps
export function formatTimestampDiff(time: number, minutesWithSeconds = false) {
  if (time < 1000) {
    return time.toFixed(0) + ' ms'
  }

  const sec = time / 1000
  if (sec < 60) {
    const toFixed = minutesWithSeconds ? 0 : 2
    return sec.toFixed(toFixed) + ' sec'
  }

  if (!minutesWithSeconds) {
    return (sec / 60).toFixed(2) + ' min'
  }

  const minutes = (sec / 60).toFixed(0)
  const seconds = (sec % 60).toFixed(0)

  return `${minutes} min` + (seconds !== '0' ? ` ${seconds} sec` : '')
}

// Get value from object by dotted path
export function getByPath(obj: any, path: string) {
  const parts = path.split('.')
  let result = obj

  for (let i = 0; i < parts.length; i++) {
    const key = parts[i]
    if (typeof result[key] === 'undefined') {
      return undefined
    }

    result = result[key]
  }

  return result
}

// Launch browser file download
export function downloadData(data: string, fileName: string) {
  const link = window.URL.createObjectURL(new File([data], fileName, {type: 'application/octet-stream'}));
  const elem = document.createElement('a');
  elem.href = link;
  elem.setAttribute('download', fileName);
  document.body.appendChild(elem);
  elem.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(link);
    document.body.removeChild(elem);
  }, 3000)
}

// Get month name by it's number with preceiding zero
export function getMonthName(number: string | number) {
  const names = [null, 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  number = parseInt(number as string)

  return names[number]
}

export function initializeMatomoAnalytics(config: ApplicationConfiguration) {
  if (!config.matomoContainerPath) {
    return
  }

  // @ts-expect-error matomo tracking
  var _mtm = window._mtm = window?._mtm || [];
   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
   g.async=true; g.src=`https://cdn.matomo.cloud/opswat.matomo.cloud/${config.matomoContainerPath}`; s?.parentNode?.insertBefore(g,s);
}

export function initializeGoogleAnalytics(config: ApplicationConfiguration) {
  if (config.googleAnalyticsID) {
    ReactGA4.initialize(config.googleAnalyticsID);
    ReactGA4.send('pageview')
  }
  if (config.googleAnalyticsUA) {
    ReactGA.initialize(config.googleAnalyticsUA)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}

export function mbToGb(value: number) {
  return Math.round(value / 1000 * 100) / 100 // Round to 2 decimal digits
}

// Check if dom element is visible in viewport
export function useIsDomNodeVisible(ref: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

export function togglePageScroll(active: boolean) {
  // Enable page scrolling and reset the screen position
  if (active) {
    document.body.style.overflow = ''
    document.body.style.position = ''
    document.body.style.maxHeight = ''

    return
  }

  // Disable page scrolling
  document.body.style.overflow = 'hidden'
  document.body.style.position = 'fixed'
  document.body.style.maxHeight = '100vh'
}

// Convert camel cased string into separate words.
// Correctly handle abbreviations (keep as  a single upper case words)
export function camelCaseToWords(str: string) {
  return str ? str.replace(/(?<![A-Z])([A-Z])/g, ' $1').replace(/([A-Z])(?![A-Z])(?=.)/g, ' $1') : '';
}

// Safely increment number
export function inc(value: number | undefined, incValue: number = 1) {
  return typeof value !== 'undefined' ? value + incValue : incValue
}

// Calculate sum of values in array or plain object
export function sum(iterable: number[] | {[key: string]: number} | undefined) {
  if (!iterable) {
    return 0
  }

  if (!Array.isArray(iterable)) {
    iterable = Object.values(iterable)
  }

  return iterable.reduce((a, b) => a + b, 0)
}

// Use array of strings as keys to create new Object with boolean `true` value
export function listToObj(list: string[] | undefined) {
  const result: {[key: string]: boolean} = {}
  for (let item of (list || [])) {
    result[item] = true
  }

  return result
}

// Just a shorthand to check if at least one of keys exists in object
export function objectHasOneOfKeys(obj: {[key: string]: any}, keys: string[]) {
  for (let key of keys) {
    if (typeof obj[key] !== 'undefined') {
      return true
    }
  }

  return false
}

// Replace new lines with spaces in string
export function noNewLines(content: string | undefined) {
  return content ? content.replace(/\n+\s*/g, ' ') : undefined
}

// Convert new lines to breaks in text
export function nlToBr(content: string) {
  const parts = content.split('\n')
  const result: (string | JSX.Element)[] = []

  if (parts.length === 1) {
    return content
  }

  parts.forEach(part => {
    result.push(part)
    result.push(<br/>)
  })

  return result
}

// Convert date obtained from BE to a Date object
export function dateObj(dateTime: string) {
  // The date is returned from the backend without timezone information and React thinks it is in local timezone
  // Need to add a Z at the end of the ISO datetime format to make it correctly parsed as UTC
  return new Date(dateTime.replace(/.{3}$/, "Z"))
}

// Convert datetime to another format and timezone
export function convertDateTime(locale: string | undefined, timezone: string | undefined, date: any) {
  try {
    if (!date) {
      return ''
    }
    if (!locale) {
      locale = navigator.languages[1] ? navigator.languages[1] : "en"
    }

    const timeZone = timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    const convertedDate = (
      dateObj(date)
        .toLocaleString(locale, { timeZoneName: 'shortOffset', timeZone: timeZone })
        .replace("GMT", "UTC")
    )

    return convertedDate
  } catch (error) {
    logger.error(error)

    return date
  }
}

// Convert date to another format
// Returns the formatted date without the time
export function formatDate(locale: string | undefined, date: any) {
  try {
    if (!locale) {
      locale = navigator.languages[1] ? navigator.languages[1] : "en"
    }

    const dateParts = new Date(date).toLocaleString(locale).split(" ")

    if (dateParts.length == 2) {
      return dateParts[0].replace(",", "")
    }

    const assembaledDate = dateParts[0] + dateParts[1] + dateParts[2]

    return assembaledDate.replace(",", "")
  } catch (error) {
    logger.error(error)

    return date
  }
}

// Detect if we are in report export mode
// Can be used everywhere on report page, unlike useExport(), that is
// limited by export context
export function useDetectExport() {
  const pageParams = useReport()
  const { subpage } = pageParams

  return subpage === 'htmlview'
}
