import { SignalVerdict } from '.'
import { mainFileKey } from './extracted-files'
import { GeolocationItem } from './geolocation'

export enum IOCFileTypes {
  Main = mainFileKey,
  ExtractedFiles = 'extractedFiles',
  DownloadedFiles = 'downloadedFiles',
  ContextFiles = 'contextFiles'
}

export enum IOCDataTypes {
  Urls = 'extractedUrls',
  Domains = 'extractedDomains',
  IPs = 'extractedIPs',
  Emails = 'extractedEmails',
  HashesMD5 = 'extractedHashesMD5',
  HashesSHA1 = 'extractedHashesSHA1',
  HashesSHA256 = 'extractedHashesSHA256',
  HashesSHA512 = 'extractedHashesSHA512',
  UUIDs = 'extractedUUIDs',
  RegistryPathways = 'extractedRegistryPathways',
  RevisionSaveIDs = 'extractedRevisionSaveIDs',
  CryptoWalletsBTC = 'extractedBTCWallets',
  CryptoWalletsETH = 'extractedETHWallets',
  CryptoWalletsXMR = 'extractedXMRWallets',
  UNCPaths = 'extractedUNCPaths'
}

export enum IOCDataTypesName {
  Urls = 'url',
  Domains = 'domain',
  IPs = 'IP',
  Emails = 'email',
  HashesMD5 = 'MD5',
  HashesSHA1 = 'SHA-1',
  HashesSHA256 = 'SHA-256',
  HashesSHA512 = 'SHA-512',
  UUIDs = 'UUID',
  RegistryPathways = 'registry pathway',
  RevisionSaveIDs = 'revision save ID',
  CryptoWalletsBTC = 'BTC wallet',
  CryptoWalletsETH = 'ETH wallet',
  CryptoWalletsXMR = 'XMR wallet',
  UNCPaths = 'UNC paths'
}

export enum IOCPrevalence {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  None = 'none'
}

export interface IOCItem {
  data: string;
  dataUUID?: string;
  dataTotalOccurrences?: number;
  geoData?: GeolocationItem;
  metaData?: {[key: string]: any};
  interesting?: boolean;
  origins?: string[];
  osintVerdicts?: any[];
  count?: {[key: string]: number};
  verdict?: SignalVerdict;
}

export interface IOCGroup {
  origin: {
    type: string;
    identifier?: string;
  };
  references: IOCItem[]
}

export interface IOCFinalGroup {
  origins: string[];
  references: IOCItem[];
  hasOsintVerdicts?: boolean;
  verdict?: number;
}

export type IOCGroupedByDataType = {
  [key in IOCDataTypes]?: IOCFinalGroup[]
}

export type IOCGroupedByFileType = {
  [key in IOCFileTypes]?: any
}

export type StrippedIOCsType = {
  [fileHash: string]: {
    [key in IOCDataTypesName]?: boolean
  }
}

export type IOCFilterStat = {
  types: {
    verdict: {[key: string]: number},
    type: {[key: string]: number},
    origin:  {[key: string]: number}
  }
  total: number
}

export type IOCPrevalenceVerdicts = {
  [ioc: string]: {
    counts: {[verdict in SignalVerdict]?: number},
    verdict: SignalVerdict
  }
}
